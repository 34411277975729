async function feedbackInit() {
	let feedbackSubmitButton = document.getElementById("submit-feedback-button");
	let feedbackEmailField = document.getElementById("email");
	let feedbackSubjectField = document.getElementById("subject");
	let feedbackMessageField = document.getElementById("message");
	let feedbackStatus = document.getElementById("feedback-status");
	let feedbackTable = document.getElementById("feedback-table");
	if (feedbackSubmitButton && feedbackEmailField && feedbackSubjectField && feedbackMessageField && feedbackStatus && feedbackTable) {
		feedbackSubmitButton.addEventListener("click", async function() {
			let EMail = feedbackEmailField.value;
			let Subject = feedbackSubjectField.value;
			let Message = feedbackMessageField.value;
			if (EMail && Subject && Message) {
				feedbackSubmitButton.hidden = true;

				if (typeof grecaptcha == 'undefined') {
					console.error('reCaptcha is unavailable, was it blocked by the client?');
					feedbackStatus.style.color = "red";
					feedbackStatus.innerHTML = "Failed to load Google reCaptcha, is it blocked via an AdBlock?<br>Unblock or allow reCaptcha, refresh the page, and try again.";
				} else {
					feedbackStatus.style.color = "white";
					feedbackStatus.innerText = "Sending feedback...";

					grecaptcha.ready(async function () {
						grecaptcha.execute(reCaptchaPublicKey, {action: 'submit'}).then(async function (token) {
							let response = await fetchData('/api/v3/feedback', 'POST',{'captcha_token': token, 'email': EMail, 'subject': Subject, 'message': Message});
							if (response.success) {
								if (!response.data.success) {
									if (response.data.error) {
										feedbackStatus.innerText = "Failed to submit feedback: " + response.data.error;
										feedbackStatus.style.color = "red";

										feedbackSubmitButton.hidden = false;
									} else {
										feedbackStatus.innerText = "Failed to submit feedback.";
										feedbackStatus.style.color = "red";

										feedbackSubmitButton.hidden = false;
									}
								} else {
									feedbackStatus.innerText = "Thanks for your feedback!";
									feedbackStatus.style.color = "green";

									feedbackTable.hidden = true;
								}
							} else {
								feedbackStatus.innerText = "Failed to submit feedback.";
								feedbackStatus.style.color = "red";
								feedbackSubmitButton.hidden = false;
							}
						});
					});
				}
			} else {
				feedbackStatus.innerText = "Please fill out all of the fields.";
				feedbackStatus.style.color = "yellow";
			}
		});
	}
}
