// Navigation bar JavaScript.
function repositionNavBar() {
	const navHeaders = document.getElementsByClassName("nav-header-content");
	for (let i = 0; i < navHeaders.length; i++) {
		if (navHeaders[i].style.width !== navHeaders[i].parentNode.offsetWidth + "px") {
			navHeaders[i].style.width = navHeaders[i].parentNode.offsetWidth + "px";
		}
		if (navHeaders[i].style.left !== navHeaders[i].parentNode.getBoundingClientRect().left + "px") {
			navHeaders[i].style.left = navHeaders[i].parentNode.getBoundingClientRect().left + "px";
		}
		if (navHeaders[i].style.right !== navHeaders[i].parentNode.getBoundingClientRect().right + "px") {
			navHeaders[i].style.right = navHeaders[i].parentNode.getBoundingClientRect().right + "px";
		}
		if (navHeaders[i].parentNode.getBoundingClientRect().top !== (navHeaders[i].parentNode.getBoundingClientRect().top + (navHeaders[i].parentNode.getBoundingClientRect().bottom - navHeaders[i].parentNode.getBoundingClientRect().top)) + "px") {
			navHeaders[i].style.top = (navHeaders[i].parentNode.getBoundingClientRect().top + (navHeaders[i].parentNode.getBoundingClientRect().bottom - navHeaders[i].parentNode.getBoundingClientRect().top)) + "px";
		}
	}
}

// Initialize.
function navBarInit() {
	const navBar = document.getElementById("nav_bar");
	if (navBar !== null) {
		navBar.addEventListener("mouseenter", repositionNavBar);
		setInterval(repositionNavBar, 100);
	}
}
