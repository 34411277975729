async function setupInstructionsInit() {
	let buttonDnd = document.getElementById("setup_dnd_button");
	let buttonSharex = document.getElementById("setup_sharex_button");
	let buttonApi = document.getElementById("setup_api_button");

	if (buttonDnd && buttonSharex && buttonApi) {
		buttonDnd.addEventListener("click", activateTheseInstructions);
		buttonSharex.addEventListener("click", activateTheseInstructions);
		buttonApi.addEventListener("click", activateTheseInstructions);
	};
};

function activateTheseInstructions() {
	let instructDnd = document.getElementById("setup_dnd");
	let instructSharex = document.getElementById("setup_sharex");
	let instructApi = document.getElementById("setup_api");

	let buttonDnd = document.getElementById("setup_dnd_button");
	let buttonSharex = document.getElementById("setup_sharex_button");
	let buttonApi = document.getElementById("setup_api_button");

	instructDnd.hidden = true;
	instructSharex.hidden = true;
	instructApi.hidden = true;

	buttonDnd.classList.remove("button-yellow");
	buttonDnd.classList.add("button-blue");
	buttonSharex.classList.remove("button-yellow");
	buttonSharex.classList.add("button-blue");
	buttonApi.classList.remove("button-yellow");
	buttonApi.classList.add("button-blue");

	let targetInstructions = document.getElementById(this.id.replace("_button", ""));
	this.hidden = false;
	targetInstructions.hidden = false;

	this.classList.remove("button-blue");
	this.classList.add("button-yellow");
};
