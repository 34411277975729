// Update table on input change(s).
async function updateVirusModTable() {
	if (document.visibilityState !== "hidden") {
		const Virus_Moderation_Table = document.getElementById("virus_moderation_table");
		if (Virus_Moderation_Table !== null) {
			const response = await fetchData("/api/v3/admin/virus-moderation/get-list", "GET");
			if (response.success) {
				for (const [index, file] of Object.entries(response.data.quarantined_file_list)) {
					let placeHolderRow = document.getElementById("vm_placeholder");
					if (placeHolderRow !== undefined && placeHolderRow !== null) {
						placeHolderRow.remove();
					}

					const targetRow = document.getElementById(file.file_name);
					if (targetRow !== null) {
						// Update?
					} else {
						const newRow = Virus_Moderation_Table.insertRow( parseInt(index) + 1);
						newRow.id = file.file_name;
						const CellEMail = newRow.insertCell(0);
						const CellUserRole = newRow.insertCell(1);
						const CellFileNames = newRow.insertCell(2);
						const CellVTReport = newRow.insertCell(3);
						const CellUploadDate = newRow.insertCell(4);
						const CellOptions = newRow.insertCell(5);

						CellEMail.innerHTML = file.file_email + " - Is Proxy/Disposable: " + file.file_email_is_temporary + "<br>Previous Infractions: <b>" + file.file_user_previous_infraction_count + "</b>";
						CellUserRole.innerHTML = file.file_user_role;
						CellFileNames.innerHTML = "<a href='/file/" + file.file_name + "?no-count'>" + file.file_name + "</a><br>" + file.file_name_original;
						CellVTReport.innerHTML = "<a href='https://www.virustotal.com/gui/file/" + file.file_sha256 + "/detection'>VirusTotal Link</a>";
						CellUploadDate.innerHTML = file.file_timeadded.replace(" ", "<br>");
						CellUploadDate.classList = "noWrap";
						CellOptions.innerHTML = "<img id='mark_as_clean_" + file.file_name + "' width='30' src='/resources/images/mark-yes.png' alt='Mark as clean button'> <img id='mark_as_unknown_" + file.file_name + "' width='30' src='/resources/images/mark-unk.png' alt='Mark as undetermined'> <img id='mark_as_virus_" + file.file_name + "' width='30' src='/resources/images/mark-no.png' alt='Mark as a virus button'>";
						CellOptions.classList = "noWrap";

						let MarkFileAsCleanButton = document.getElementById("mark_as_clean_" + file.file_name);
						MarkFileAsCleanButton.addEventListener("click", markFileAsClean);

						let MarkFileAsUnknownButton = document.getElementById("mark_as_unknown_" + file.file_name);
						MarkFileAsUnknownButton.addEventListener("click", markFileAsUnknown);

						let MarkFileAsVirusButton = document.getElementById("mark_as_virus_" + file.file_name);
						MarkFileAsVirusButton.addEventListener("click", markFileAsVirus);
					}
				}

				// Build file counts.
				let fileIdList = [];
				for (const [_, file] of Object.entries(response.data.quarantined_file_list)) {
					fileIdList.push(file.file_name);
				}

				// Build list of rows we need to delete.
				let deletedFilesList = [];
				for (let i = 0, row; row = Virus_Moderation_Table.rows[i]; i++) {
					if (row.id && !fileIdList.includes(row.id)) {
						deletedFilesList.push(row);
					}
				}

				// Delete rows that we need to delete.
				if (deletedFilesList.length > 0) {
					for (let row of deletedFilesList) {
						row.classList.remove("visible");
						row.classList.add("hidden");
						row.style.height = row.offsetHeight + "px";
					}
					await sleep(100);
					while (deletedFilesList[0].offsetHeight > 0) {
						for (let row of deletedFilesList) {
							if (row.offsetHeight > 0) {
								if (row.innerHTML !== "") {
									row.innerHTML = "";
								}
								row.style.height = (row.offsetHeight - 1) + "px";
							}
						}
						await sleep(1);
					}
					await sleep(100);
					for (let row of deletedFilesList) {
						row.remove();
					}
				}

				// If this user doesn't have any files, add a placeholder.
				if (Virus_Moderation_Table.rows.length === 2) {
					let placeHolderRow = document.getElementById("vm_placeholder");
					if (placeHolderRow !== undefined && placeHolderRow !== null) {
						placeHolderRow.remove();
					}

					const newRow = Virus_Moderation_Table.insertRow();
					newRow.id = "vm_placeholder";
					const newCell = newRow.insertCell();
					newCell.colSpan = 6;
					newCell.innerHTML = "<td id='vm_placeholder'>There is nothing in the virus moderation queue!</td>";
				}
			}
		}
	}
}

async function markFileAsClean() {
	const targetFileID = this.id.replace("mark_as_clean_", "");
	const targetFileRow = document.getElementById(targetFileID);

	targetFileRow.style.backgroundColor = "#FFFF00";
	targetFileRow.style.color = "black";
	targetFileRow.innerHTML = "<td colspan='6'><b>Marking file as clean.</b><br>Please wait...</td>";

	await sleep(500);

	if ((await fetchData("/api/v3/admin/virus-moderation/action", "POST", {"mark_as_clean": targetFileID})).success) {
		targetFileRow.style.backgroundColor = "#00FF00";
		targetFileRow.style.color = "black";
		targetFileRow.innerHTML = "<td colspan='6'><b>File marked as clean!</b><br>Row will be removed on next update.</td>";
		console.log("Marked file " + targetFileID + " as clean.")
	}
}

async function markFileAsUnknown() {
	const targetFileID = this.id.replace("mark_as_unknown_", "");
	const targetFileRow = document.getElementById(targetFileID);

	targetFileRow.style.backgroundColor = "#FFFF00";
	targetFileRow.style.color = "black";
	targetFileRow.innerHTML = "<td colspan='6'><b>Marking file as unknown.</b><br>Please wait...</td>";

	await sleep(500);

	if ((await fetchData("/api/v3/admin/virus-moderation/action", "POST", {"mark_as_unknown": targetFileID})).success) {
		targetFileRow.style.backgroundColor = "#FFFF00";
		targetFileRow.style.color = "black";
		targetFileRow.innerHTML = "<td colspan='6'><b>File marked as unknown!</b><br>Row will be removed on next update.</td>";
		console.log("Marked file " + targetFileID + " as clean.")
	}
}

async function markFileAsVirus() {
	const targetFileID = this.id.replace("mark_as_virus_", "");
	const targetFileRow = document.getElementById(targetFileID);

	targetFileRow.style.backgroundColor = "#FFFF00";
	targetFileRow.style.color = "black";
	targetFileRow.innerHTML = "<td colspan='6'><b>Marking file as a virus.</b><br>Please wait...</td>";

	await sleep(500);

	if ((await fetchData("/api/v3/admin/virus-moderation/action", "POST", {"mark_as_virus": targetFileID})).success) {
		targetFileRow.style.backgroundColor = "#FF0000";
		targetFileRow.style.color = "black";
		targetFileRow.innerHTML = "<td colspan='6'><b>File marked as virus!</b><br>Row will be removed on next update.</td>";
		console.log("Marked file " + targetFileID + " as a virus.")
	}
}

// Initialize.
function virusModInit() {
	// Virus moderation table
	const Virus_Moderation_Table = document.getElementById("virus_moderation_table");
	if (Virus_Moderation_Table !== null) {
		updateVirusModTable();

		setInterval(updateVirusModTable, 15000);

		window.addEventListener("focus", updateVirusModTable);
	}
}

// Start scripts when the page is done loading.
window.addEventListener("load", function() {
	virusModInit();
});
