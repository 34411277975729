// Track uploads for this session.
let uploadedFiles = [];
const dropZoneHTML = "<br>Drag and drop files here!<br><br>Or click/tap here!";
let uploadInProgress = false;
let dragEventCount = 0;

// Init this script.
async function browserUploadInit() {
    const browserUploadContainer = document.getElementById("browser-upload");
    if (!browserUploadContainer) {
        return;
    }

    let isLoggedIn = null;
    let response = await fetchData("/api/v3/session/is-logged-in", "GET");
    if (response.success) {
        isLoggedIn = response.data.logged_in;
    }

    window.addEventListener("dragenter", function(event) {
        dragEventCount++;
        event.preventDefault();

        if (isLoggedIn) {
            let thisDropZone = document.getElementById("drop-zone");
            if (uploadInProgress) {
                thisDropZone.classList.remove("drop-zone-idle");
                thisDropZone.classList.remove("drop-zone-nearby");
                thisDropZone.classList.remove("drop-zone-active");
                thisDropZone.classList.add("drop-zone-ul-in-progress");
            } else if (thisDropZone && (window === event.target || thisDropZone !== event.target)) {
                thisDropZone.classList.remove("drop-zone-idle");
                thisDropZone.classList.add("drop-zone-nearby");
                thisDropZone.classList.remove("drop-zone-active");
                thisDropZone.classList.remove("drop-zone-ul-in-progress");
            }
        }
    });

    window.addEventListener("dragleave", function(event) {
        dragEventCount--;
        event.preventDefault();

        if (isLoggedIn) {
            let thisDropZone = document.getElementById("drop-zone");
            if (thisDropZone && dragEventCount === 0) {
                thisDropZone.classList.add("drop-zone-idle");
                thisDropZone.classList.remove("drop-zone-nearby");
                thisDropZone.classList.remove("drop-zone-active");
                thisDropZone.classList.remove("drop-zone-ul-in-progress");
            }
        }
    });

    // Prevent drag and drop defaults.
    ["drop", "drag", "dragstart", "dragover", "dragend"].forEach(eventName => {
        window.addEventListener(eventName, function(event) {
            event.preventDefault();

            if (isLoggedIn) {
                if (eventName === "drop") {
                    let thisDropZone = document.getElementById("drop-zone");
                    if (thisDropZone) {
                        thisDropZone.classList.add("drop-zone-idle");
                        thisDropZone.classList.remove("drop-zone-nearby");
                        thisDropZone.classList.remove("drop-zone-active");
                        thisDropZone.classList.remove("drop-zone-ul-in-progress");

                        if (event.target !== thisDropZone) {
                            let dropZoneText = document.getElementById("drop_zone_text");
                            if (dropZoneText) {
                                dropZoneText.innerHTML = dropZoneText.innerHTML.replace("in here", "<text style='color: red;'>in here</text>");
                                dropZoneText.innerHTML = dropZoneText.innerHTML.replace("Or click here to select some", "(directly into this box)");
                            }
                        }
                    }
                    dragEventCount = 0;
                }
            }
        });
    });

    if (browserUploadContainer) {
        let thisHTMLContents = "";
        thisHTMLContents += "<div class='drop-zone drop-zone-idle' id='drop-zone'>";
        thisHTMLContents += "   <div id='upload_progress' class='drop-zone-progress drop-zone-no-interact' style='width: 0;'></div>";
        thisHTMLContents += "   <div id='drop_zone_text' class='drop-zone-no-interact'>";
        thisHTMLContents += dropZoneHTML;
        thisHTMLContents += "   </div>";
        thisHTMLContents += "   <form class='hidden' id='browser-upload-form'>";
        thisHTMLContents += "       <input class='hidden' id='target-browser-file' type='file' multiple>";
        thisHTMLContents += "   </form>";
        thisHTMLContents += "</div>";
        thisHTMLContents += "<br>";
        thisHTMLContents += "<table id='uploaded_files'>";
        thisHTMLContents += "   <thead>";
        thisHTMLContents += "      <tr><td colspan='2'>New uploads are added to the top of the list!</td></tr>";
        thisHTMLContents += "      <tr><th colspan='2' class='top left'>Files</th></tr>";
        thisHTMLContents += "   </thead>";
        thisHTMLContents += "   <tbody>";
        thisHTMLContents += "      <tr id='drag_n_drop_filler_row'><td colspan='2'>When an upload completes, it will show here!</td></tr>";
        thisHTMLContents += "   </tbody>";
        thisHTMLContents += "</table>";

        browserUploadContainer.innerHTML = thisHTMLContents;

        let thisFileForm = document.getElementById("target-browser-file");
        let thisDropZone = document.getElementById("drop-zone");
        if (thisFileForm != null && thisDropZone != null) {
            if (isLoggedIn) {
                let formSelect = document.getElementById("target-browser-file");

                ['click', 'touchend'].forEach(eventName => {
                    thisDropZone.addEventListener(eventName, function() {
                        if (!uploadInProgress && isLoggedIn) {
                            formSelect.click();
                        }
                    });
                });

                thisDropZone.addEventListener("dragenter", function(event) {
                    if (uploadInProgress) {
                        thisDropZone.classList.remove("drop-zone-idle");
                        thisDropZone.classList.remove("drop-zone-nearby");
                        thisDropZone.classList.remove("drop-zone-active");
                        thisDropZone.classList.add("drop-zone-ul-in-progress");
                    } else if (event.target === thisDropZone) {
                        thisDropZone.classList.remove("drop-zone-idle");
                        thisDropZone.classList.remove("drop-zone-nearby");
                        thisDropZone.classList.add("drop-zone-active");
                        thisDropZone.classList.remove("drop-zone-ul-in-progress");
                    }
                });

                thisDropZone.addEventListener("dragleave", function(event) {
                    if (uploadInProgress) {
                        thisDropZone.classList.remove("drop-zone-idle");
                        thisDropZone.classList.remove("drop-zone-nearby");
                        thisDropZone.classList.remove("drop-zone-active");
                        thisDropZone.classList.add("drop-zone-ul-in-progress");
                    } else if (event.target === thisDropZone) {
                        thisDropZone.classList.remove("drop-zone-idle");
                        thisDropZone.classList.add("drop-zone-nearby");
                        thisDropZone.classList.remove("drop-zone-active");
                        thisDropZone.classList.remove("drop-zone-ul-in-progress");
                    }
                });

                thisDropZone.addEventListener("drop", processDrop);
                formSelect.addEventListener("change", processDrop);
            }
        }

        if (!isLoggedIn) {
            let thisDropZoneText = document.getElementById("drop_zone_text");
            if (thisDropZoneText) {
                thisDropZoneText.innerHTML = "<br>You are not signed in!<br><br>You need to <a href='/login'>sign in</a> first!";
            }
            if (thisDropZone) {
                thisDropZone.classList.remove("drop-zone-idle");
                thisDropZone.classList.remove("drop-zone-nearby");
                thisDropZone.classList.remove("drop-zone-active");
                thisDropZone.classList.add("drop-zone-ul-in-progress");
            }
        }
    }
}

// Handle the file drop.
async function processDrop(thisEvent) {
    if (uploadInProgress) {
        return;
    }

    uploadInProgress = true;

    let files;
    if (thisEvent.type === "change") {
        // Selected file(s) handler.
        let thisFileForm = document.getElementById("target-browser-file");
        files = [...thisFileForm.files];
    } else {
        // Drag and drop file handler.
        let thisDropZone = document.getElementById("drop-zone");
        thisDropZone.classList.add("drop-zone-idle");
        thisDropZone.classList.remove("drop-zone-active");
        thisDropZone.classList.remove("drop-zone-ul-in-progress");
        thisDropZone.classList.remove("drop-zone-nearby");

        let transfer = thisEvent.dataTransfer;
        files = transfer.files;
    }

    let fileNum = 1;
    for (let thisFile of files) {
        await uploadFile(thisFile, files.length, fileNum);
        fileNum++;
    }

    let thisDropZoneText = document.getElementById("drop_zone_text");
    if (thisDropZoneText) {
        thisDropZoneText.innerHTML = dropZoneHTML;
    }
    let thisProgressBarDiv = document.getElementById("upload_progress");
    if (thisProgressBarDiv) {
        thisProgressBarDiv.style.width = "0";
    }

    uploadInProgress = false;
}

async function uploadFile(file, fileCount, fileNum) {
    let destination = "https://lobfile.com/api/v3/upload";
    let formData = new FormData();
    let attemptNum = 0;
    let currentStatus = 0;

    let thisProgressBarDiv = document.getElementById("upload_progress");
    let thisDropZoneText = document.getElementById("drop_zone_text");

    let trimmedFileName
    if (file.name.length > 32) {
        let thisFilePieces = file.name.split(".");
        let thisFileName = file.name.substring(0, 32);
        let thisFileExt = thisFilePieces[thisFilePieces.length - 1];
        trimmedFileName = thisFileName + "..." + thisFileExt;
    } else {
        trimmedFileName = file.name;
    }

    while (attemptNum < 5 && currentStatus !== 200) {
        thisDropZoneText.innerHTML = "<br>" + trimmedFileName + "<br>0%<br>Please wait...";

        let xhr = new XMLHttpRequest();
        attemptNum += 1;
        xhr.open('POST', destination, true)

        xhr.upload.addEventListener("progress", function(e) {
            thisProgressBarDiv.style.width = ((e.loaded * 100.0 / e.total) || 100) + "%";
            thisDropZoneText.innerHTML = "<br>" + trimmedFileName + "<br>" + Math.round((e.loaded * 100.0 / e.total) || 100) + "%<br>";
            if (dragEventCount > 0) {
                thisDropZoneText.innerHTML += "UPLOAD ALREADY IN PROGRESS!";
            } else {
                thisDropZoneText.innerHTML += "File " + fileNum + "/" + fileCount + ", please wait...";
            }
        });

        xhr.addEventListener('readystatechange', async function() {
            currentStatus = xhr.status;
            if (xhr.readyState === 4 && xhr.status === 200) {
                thisProgressBarDiv.style.width = "0";
                let thisData = JSON.parse(xhr.responseText) || false;
                if (thisData.success === true && thisData.url) {
                    uploadedFiles.unshift(thisData.url)
                }
            } else if (xhr.readyState === 4 && xhr.status !== 200) {
                if (xhr.status === 429) {
                    console.log("Rate limit hit, retrying in one second..");
                    uploadedFiles.unshift("Error: Rate limit exceeded, retry in 1 second...");
                } else {
                    // To stop retries.
                    attemptNum = 5;

                    if (xhr.responseText) {
                        let thisData = JSON.parse(xhr.responseText) || false;
                        if (thisData) {
                            let errorMessage = "Upload error: " + thisData.error;
                            console.error(errorMessage);

                            if (typeof Sentry != "undefined") {
                                Sentry.captureMessage(errorMessage);
                            }

                            uploadedFiles.unshift("Error: " + thisData.error);
                        }
                    } else {
                        let errorMessage = "Generic upload error, status: " + xhr.status;
                        console.error(errorMessage);

                        if (typeof Sentry != "undefined") {
                            Sentry.captureMessage(errorMessage);
                        }

                        uploadedFiles.unshift("Generic Error, status: " + xhr.status);
                    }
                }
            }
            await updateUploadedFileList();
        })
        formData.append('file', file);
        xhr.send(formData);

        // This prevents the loop from starting and aborting the first three requests instantly.
        while (xhr.readyState !== 4 && attemptNum < 5) {
            await sleep(1000);
        }
    }
}

// Update uploaded file list.
async function updateUploadedFileList() {
    let uploadedFileList = document.getElementById("uploaded_files");
    if (uploadedFileList) {
        let tBody = uploadedFileList.tBodies[0];

        for (let i = 0; i < uploadedFiles.length; i++) {
            let thisNewRow = tBody.insertRow(0);
            let thisNewCol = thisNewRow.insertCell(0);
            if (uploadedFiles[i].includes("Error") || uploadedFiles[i].includes("error") || uploadedFiles[i].includes("ERROR")) {
                thisNewCol.colSpan = 2;
                if (uploadedFiles[i].includes("No API key given")) {
                    thisNewCol.innerHTML = "<text style='color: red;'>" + uploadedFiles[i] + " (are you signed in?)</text><br>";
                } else if (uploadedFiles[i].includes("You haven't activated")) {
                    thisNewCol.innerHTML = "<text style='color: red;'>Error: E-Mail isn't verified</text><br>";
                } else {
                    thisNewCol.innerHTML = "<text style='color: red;'>" + uploadedFiles[i] + "</text><br>";
                }
            } else {
                thisNewCol.innerHTML = "<a target='_blank' href='" + uploadedFiles[i] + "'>" + uploadedFiles[i] + "</a><br>";
                let thisNewCol2 = thisNewRow.insertCell(1);

                let thisCopyButton = document.createElement("button");
                thisCopyButton.innerText = "Copy Link";
                thisCopyButton.classList.add("button-copy");
                let thisCopyText = uploadedFiles[i];
                thisCopyButton.addEventListener("click", function() {
                    navigator.clipboard.writeText(thisCopyText).then(function() {
                        thisCopyButton.innerText = "Copied!";
                        thisCopyButton.style.backgroundColor = "green";
                        setTimeout(function() {
                            thisCopyButton.innerText = "Copy Link"
                            thisCopyButton.style = null;
                        }, 5000);
                    }, function(err) {
                        thisCopyButton.innerText = "Failed!";
                        thisCopyButton.style.backgroundColor = "darkred";
                    });
                });
                thisNewCol2.appendChild(thisCopyButton);
            }

            uploadedFiles.splice(i);
        }

        let fillerRow = document.getElementById("drag_n_drop_filler_row");
        if (fillerRow) {
            fillerRow.remove();
        }
    }
}
