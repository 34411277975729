let currentDataSetTimeStamp = 0;

// Update table on input change(s).
async function updateStats() {
	let userAgentSearchString = document.getElementById("stats_search_user_agent").value;
	if (userAgentSearchString === null) {
		userAgentSearchString = "";
	}
	let userAgentSearchStart = document.getElementById("stats_search_num_days").value;
	if (userAgentSearchStart === null || userAgentSearchStart === "") {
		userAgentSearchStart = 0;
	}
	if (document.visibilityState !== "hidden") {
		let thisDate = new Date();
		await fetchData("/api/v3/stats-user-agent", "POST", {"stats_search_user_agent": userAgentSearchString, "stats_search_num_days": userAgentSearchStart, "request_started_at": thisDate.getTime() + (thisDate.getTimezoneOffset() * 60 * 1000)}, buildUserAgentStatsTables);
	}
}

// Build table.
async function buildUserAgentStatsTables(data) {
	if (data.success !== undefined) {
		if (data.request_started_at > currentDataSetTimeStamp) {
			currentDataSetTimeStamp = data.request_started_at;
			const User_Agent_Per_Account_Count_Table = document.getElementById("user_agent_count_per_account_stats_table");
			User_Agent_Per_Account_Count_Table.innerHTML = "<tr><th colspan='2' class='noWrap top left'>Per Account</th></tr>";
			User_Agent_Per_Account_Count_Table.innerHTML += "<tr><th class='noWrap left'>User-Agent</th><th class='noWrap'>User Count</th></tr>";

			let currentRowNum = 0;
			for (const [index, item] of Object.entries(data.user_agent_count_by_account)) {
				let ThisPair = Object.entries(item).flat();
				let UserAgent = ThisPair[0];
				let Count = ThisPair[1];

				const newRow = User_Agent_Per_Account_Count_Table.insertRow( currentRowNum + 2);
				const CellUA = newRow.insertCell(0);
				const CellCount = newRow.insertCell(1);

				CellUA.innerHTML = UserAgent;
				CellCount.innerHTML = Count;

				currentRowNum += 1;
			}

			if (currentRowNum === 0) {
				const newRow = User_Agent_Per_Account_Count_Table.insertRow( currentRowNum + 2);
				const newCell = newRow.insertCell(0);
				newCell.colSpan = 2;
				newCell.innerText = "[ No results ]";
			}

			const User_Agent_Per_Upload_Count_Table = document.getElementById("user_agent_count_per_upload_stats_table");
			User_Agent_Per_Upload_Count_Table.innerHTML = "<tr><th colspan='2' class='noWrap top left'>Per Upload</th></tr>";
			User_Agent_Per_Upload_Count_Table.innerHTML += "<tr><th class='noWrap left'>User-Agent</th><th class='noWrap'>Upload Count</th></tr>";

			currentRowNum = 0;
			for (const [index, item] of Object.entries(data.user_agent_count_by_upload)) {
				let ThisPair = Object.entries(item).flat();
				let UserAgent = ThisPair[0];
				let Count = ThisPair[1];

				const newRow = User_Agent_Per_Upload_Count_Table.insertRow( currentRowNum + 2);
				const CellUA = newRow.insertCell(0);
				const CellCount = newRow.insertCell(1);

				CellUA.innerHTML = UserAgent;
				CellCount.innerHTML = Count;

				currentRowNum += 1;
			}

			if (currentRowNum === 0) {
				const newRow = User_Agent_Per_Upload_Count_Table.insertRow( currentRowNum + 2);
				const newCell = newRow.insertCell(0);
				newCell.colSpan = 2;
				newCell.innerText = "[ No results ]";
			}
		}
	}
}

// Initialize.
function statsInit() {
	// File table
	const User_Agent_Per_Account_Count_Table = document.getElementById("user_agent_count_per_account_stats_table");
	if (User_Agent_Per_Account_Count_Table !== null) {
		User_Agent_Per_Account_Count_Table.innerHTML = "<tr><th colspan='2' class='noWrap top left'>Per Account</th></tr>";
		User_Agent_Per_Account_Count_Table.innerHTML += "<tr><th class='noWrap left'>User Count</th><th class='noWrap'>User-Agent</th></tr>";
		User_Agent_Per_Account_Count_Table.innerHTML += "<tr><td colspan='2' class='noWrap'>Fetching data..</td></tr>";
	}

	const User_Agent_Per_Upload_Count_Table = document.getElementById("user_agent_count_per_upload_stats_table");
	if (User_Agent_Per_Upload_Count_Table !== null) {
		User_Agent_Per_Upload_Count_Table.innerHTML = "<tr><th colspan='2' class='noWrap top left'>Per Upload</th></tr>";
		User_Agent_Per_Upload_Count_Table.innerHTML += "<tr><th class='noWrap left'>Upload Count</th><th class='noWrap'>User-Agent</th></tr>";
		User_Agent_Per_Upload_Count_Table.innerHTML += "<tr><td colspan='2' class='noWrap'>Fetching data..</td></tr>";
	}

	const statsUserAgentSearch = document.getElementById("stats_search_user_agent");
	if (statsUserAgentSearch !== null) {
		statsUserAgentSearch.addEventListener("input", updateStats);
	}
	const statsEpochStartSearch = document.getElementById("stats_search_num_days");
	if (statsEpochStartSearch !== null) {
		statsEpochStartSearch.addEventListener("input", updateStats);
	}

	if (User_Agent_Per_Account_Count_Table !== null && User_Agent_Per_Upload_Count_Table !== null) {
		updateStats();

		// If the tab is re-opened/focused after it being open, make sure the data is good.
		window.addEventListener("focus", updateStats);
	}
}
