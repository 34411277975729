// Lists for preview-enabled file-extensions.
const previewImageType = ["apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "svg", "webp"];
const previewVideoType = ["webm", "mp4", "ogg", "mov"];

const refreshListButton = document.getElementById("refreshFileListButton");
const showFilePreviewsCheckBox = document.getElementById("fm_show_preview");
const filePreviewOverlay = document.getElementById("file_preview_overlay");
const fileTable = document.getElementById("fileTable");
const FM_DeleteSelectedButton = document.getElementById("fm_delete_selected_button");
const FM_SelectAllCheckBox = document.getElementById("fm_select_all");

// Update the existing file list.
async function parseFileTable() {
	refreshListButton.innerHTML = "Loading... <img src='/resources/images/loading.gif' height='16' alt='Loading...'>";
	if (fileTable !== null) {
		const tBody = fileTable.tBodies[0];

		const sortName = document.getElementById("sort_name");
		const sortFavorite = document.getElementById("sort_favorite");
		const sortSize = document.getElementById("sort_size");
		const sortHits = document.getElementById("sort_hits");
		const sortAccessed = document.getElementById("sort_accessed");
		const sortAdded = document.getElementById("sort_added");

		let sortString = "";

		if (sortName.dataset.sortDirection) {
			sortString += "?column=name&direction=" + sortName.dataset.sortDirection;
		} else if (sortFavorite.dataset.sortDirection) {
			sortString += "?column=favorite&direction=" + sortFavorite.dataset.sortDirection;
		} else if (sortSize.dataset.sortDirection) {
			sortString += "?column=size&direction=" + sortSize.dataset.sortDirection;
		} else if (sortHits.dataset.sortDirection) {
			sortString += "?column=hits&direction=" + sortHits.dataset.sortDirection;
		} else if (sortAccessed.dataset.sortDirection) {
			sortString += "?column=access_time&direction=" + sortAccessed.dataset.sortDirection;
		} else if (sortAdded.dataset.sortDirection) {
			sortString += "?column=upload_time&direction=" + sortAdded.dataset.sortDirection;
		}

		let response = await fetchData("/api/v3/rest/get-file-list" + sortString, "GET");
		if (response.success) {
			let fileListData = response.data;
			tBody.innerHTML = "<tr id='rowPlaceHolder'><td colspan=\"9\">Loading files... <img src='/resources/images/loading.gif' height='16' alt='Loading...'></td></tr>";

			tBody.addEventListener("mouseenter", showHoverfilePreviewOverlay);
			tBody.addEventListener("mouseleave", hideHoverfilePreviewOverlay);

			// Parse rows with the new data.
			for (const [index, file] of Object.entries(fileListData.file_list)) {
				let thisNewRow = document.createElement("tr");
				thisNewRow.id = file.name;

				thisNewRow.setAttribute("file_name", file.name);
				thisNewRow.setAttribute("file_extension", file.extension);

				thisNewRow.addEventListener("mouseenter", buildPreview);

				const selectCell = thisNewRow.insertCell(0);
				const nameCell = thisNewRow.insertCell(1);
				const copyButtonCell = thisNewRow.insertCell(2);
				const isFavoriteCell = thisNewRow.insertCell(3);
				const sizeCell = thisNewRow.insertCell(4);
				const hitsCell = thisNewRow.insertCell(5);
				const lastAccessedCell = thisNewRow.insertCell(6);
				const uploadTimeCell = thisNewRow.insertCell(7);

				selectCell.innerHTML = '<input type="checkbox" id="file_select_' + file.name + '" name="file_select">';
				selectCell.className = "noWrap";

				let fileNameDisplayString = "";
				if (file.name.length > 8) {
					fileNameDisplayString = file.name.substring(0, 8) + "..";
				} else {
					fileNameDisplayString = file.name;
				}

				if (fileListData.show_file_extensions) {
					fileNameDisplayString += "." + file.extension;
				}
				nameCell.innerHTML = "<a title='" + file.name + "." + file.extension + "' target=\"_blank\" href=\"https://" + fileListData.preferred_domain + "/file/" + file.name + "." + file.extension + "\">" + styleUrlString(fileNameDisplayString) + "</a>";
				nameCell.className = "noWrap";

				let thisCopyButton = document.createElement("button");
				let thisCopyText = "https://" + fileListData.preferred_domain + "/file/" + file.name;
				if (fileListData.show_file_extensions) {
					thisCopyText += "." + file.extension;
				}
				thisCopyButton.classList.add("button-copy");
				thisCopyButton.innerText = "Copy Link";
				thisCopyButton.addEventListener("click", function() {
					navigator.clipboard.writeText(thisCopyText).then(function() {
						thisCopyButton.innerText = "Copied!";
						thisCopyButton.style.backgroundColor = "green";
						setTimeout(function() {
							thisCopyButton.innerText = "Copy Link"
							thisCopyButton.style = null;
						}, 2000);
					}, function(err) {
						thisCopyButton.innerText = "Failed!";
						thisCopyButton.style.backgroundColor = "darkred";
					});
				});
				copyButtonCell.appendChild(thisCopyButton);

				isFavoriteCell.innerHTML = fileImportantLinkImage(file.is_favorite);
				isFavoriteCell.className = "noWrap";
				isFavoriteCell.id = "file_is_favorite_" + file.name;
				isFavoriteCell.addEventListener("click", FM_ToggleFileImportant);

				// ----------------------------

				sizeCell.innerHTML = formatByteSize(file.size);
				sizeCell.className = "noWrap";

				hitsCell.innerHTML = formatNumber(file.hits);
				hitsCell.className = "noWrap";
				hitsCell.id = "file_hits_" + file.name;

				lastAccessedCell.innerHTML = file.last_accessed.replace(" ", "<br>");
				lastAccessedCell.id = "file_last_accessed_" + file.name;

				uploadTimeCell.innerHTML = file.time_added.replace(" ", "<br>");

				fileTable.tBodies[0].appendChild(thisNewRow);
			}

			// Build file counts.
			let fileHitSum = 0;
			let accountBandwidthSum = 0;
			for (const [_, file] of Object.entries(fileListData.file_list)) {
				fileHitSum += file.hits;
				accountBandwidthSum += (file.hits * file.size);
			}

			// If this user doesn't have any files, add a placeholder.
			let rowPlaceHolder = document.getElementById("rowPlaceHolder");
			if (rowPlaceHolder) {
				if (fileTable.rows.length === (3)) {
					rowPlaceHolder.innerHTML = "<td id='rowPlaceHolder' colspan='9'>You don't have any files. Upload one and it will show up here!</td>";
					rowPlaceHolder.hidden = false;
				} else {
					rowPlaceHolder.hidden = true;
				}
			}
		}
	}
	refreshListButton.innerHTML = "Refresh List";
}

// Show file preview overlay.
async function showHoverfilePreviewOverlay() {
	let placeHolderRow = document.getElementById("rowPlaceHolder");
	if (placeHolderRow.hidden && showFilePreviewsCheckBox.checked && filePreviewOverlay.classList.contains("hidden")) {
		resizefilePreviewOverlay();
		filePreviewOverlay.classList.remove("hidden");
	}
}

// Hide file preview overlay.
async function hideHoverfilePreviewOverlay() {
	if (!filePreviewOverlay.classList.contains("hidden")) {
		filePreviewOverlay.classList.add("hidden");
	}
}

// Configure preview in the overlay.
async function buildPreview() {
	if (showFilePreviewsCheckBox.checked) {
		let currentPreview = filePreviewOverlay.getAttribute("currentPreview");
		if (currentPreview !== this.getAttribute("file_name")) {
			updateIfDifferentHTMLContents(filePreviewOverlay, "");

			if (this.getAttribute("file_name") === "deleted") {
				let thisImage = new Image();
				thisImage.src = "/resources/images/file-deleted.png";
				thisImage.classList = "global-preview-object";
				filePreviewOverlay.appendChild(thisImage);
			} else if (previewImageType.includes(this.getAttribute("file_extension"))) {
				let thisImage = new Image();
				thisImage.src = "/file/" + this.getAttribute("file_name") + "." + this.getAttribute("file_extension") + "?no-count";
				thisImage.classList = "global-preview-object";
				filePreviewOverlay.appendChild(thisImage);
			} else if (previewVideoType.includes(this.getAttribute("file_extension"))) {
				let thisVideo = document.createElement("video");
				thisVideo.onerror = function() { if (thisVideo) { thisVideo.src = "/resources/images/preview-unavailable.png" } };
				thisVideo.src = "/file/" + this.getAttribute("file_name") + "." + this.getAttribute("file_extension") + "?no-count";
				thisVideo.classList = "global-preview-object";
				thisVideo.autoplay = true;
				thisVideo.loop = true;
				thisVideo.muted = true;
				filePreviewOverlay.appendChild(thisVideo);
			} else {
				let thisImage = new Image();
				thisImage.src = "/resources/images/preview-unavailable.png";
				thisImage.classList = "global-preview-object";
				filePreviewOverlay.appendChild(thisImage);
			}

			filePreviewOverlay.setAttribute("currentPreview", this.getAttribute("file_name"));
		}
	}
}

// Resize the file preview overlay.
function resizefilePreviewOverlay() {
	const filePreviewOverlay = document.getElementById("file_preview_overlay");
	const isFavoriteColumn = document.getElementById("isFavoriteColumn");
	filePreviewOverlay.style.width = window.innerWidth - isFavoriteColumn.getBoundingClientRect().right + "px";
	filePreviewOverlay.style.left = (isFavoriteColumn.getBoundingClientRect().right + 1) + "px";
	filePreviewOverlay.style.right = (window.innerWidth - 1) + "px";
	filePreviewOverlay.style.top = "0px";
	filePreviewOverlay.style.height = window.innerHeight + "px";
	filePreviewOverlay.style.bottom = window.innerHeight + "px";
}

// Toggle a files importance.
async function FM_ToggleFileImportant() {
	if (this.id !== null) {
		this.innerHTML = "<img src=\"/resources/images/loading.gif\" height=\"32\" alt=\"Loading...\">";
		await fetchData("/api/v3/rest/toggle-favorite", "POST", {"file": this.id.replace("file_is_favorite_", "")}, FM_ToggleFileImportantDone);
	}
}

// Importance toggle complete.
async function FM_ToggleFileImportantDone(data) {
	if (data !== null) {
		if (data.success === true) {
			const isFavoriteCell = document.getElementById("file_is_favorite_" + data.file);
			isFavoriteCell.addEventListener("click", FM_ToggleFileImportant);
			isFavoriteCell.addEventListener("touch", FM_ToggleFileImportant);
			updateIfDifferentHTMLContents(isFavoriteCell, fileImportantLinkImage(data.is_favorite));
		}
	}
}

// Generate html for favorites.
function fileImportantLinkImage(fileIsFavorite) {
	if (fileIsFavorite === true) {
		return "<img src='/resources/images/star-yellow.png' style='height: 3vh;' alt='File marked as favorite.'>";
	} else {
		return "<img src='/resources/images/star-grey.png' style='height: 3vh;' alt='File marked as not favorite.'>";
	}
}

// Toggle select all.
async function toggleSelectAll() {
	const selectAllCheckbox = document.getElementById("fm_select_all");
	const allCheckBoxes = document.getElementsByName("file_select");
	if (selectAllCheckbox.checked) {
		for (let i = 0; i < allCheckBoxes.length; i++) {
			allCheckBoxes[i].checked = true;
		}
	} else {
		for (let i = 0; i < allCheckBoxes.length; i++) {
			allCheckBoxes[i].checked = false;
		}
	}
}

// Build delete selected files overlay.
async function buildDeleteSelectedOverlay() {
	const globalOverlay = document.getElementById("g_overlay");
	const filesMarkedForDelete = document.getElementsByName("file_select");
	let markedFiles = [];
	for (let i = 0; i < filesMarkedForDelete.length; i++) {
		if (filesMarkedForDelete[i].checked) {
			markedFiles.push(filesMarkedForDelete[i].id.replace("file_select_", ""));
		}
	}

	if (markedFiles.length === 0) {
		let newHTML = "<div class='inner-global-overlay'>";
		newHTML += "<h1>Delete Selected File(s)</h1>";
		newHTML += "<hr>";
		newHTML += "<b>You haven't selected any file(s) to delete!</b>";
		newHTML += "<br>";
		newHTML += "<i>Note:</i> Use the check boxes on the left!";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<button id='close_overlay' class='button-blue'>Okay</button>";
		newHTML += "</div>";
		globalOverlay.innerHTML = newHTML;
	} else {
		let newHTML = "<div class='inner-global-overlay'>";
		newHTML += "<h1>Delete Selected File(s)</h1>";
		newHTML += "<hr>";
		newHTML += "<b>This will remove all the file(s) you have selected.</b>";
		newHTML += "<br>";
		newHTML += "Are you sure you want to delete <b>" + markedFiles.length + "</b> file(s)?";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<button id='confirm_delete_files' class='button-red'>Delete them</button><button id='close_overlay' class='button-yellow'>Nevermind</button>";
		newHTML += "</div>";
		globalOverlay.innerHTML = newHTML;

		const confirmDeleteFilesButton = document.getElementById("confirm_delete_files");
		confirmDeleteFilesButton.addEventListener("click", deleteSelectedFiles);
		confirmDeleteFilesButton.addEventListener("touchstart", deleteSelectedFiles);
	}

	const closeOverlayButton = document.getElementById("close_overlay");
	closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
	closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

	globalOverlay.classList.remove("hidden");
	globalOverlay.style.height = "100%";
}

// Delete the selected files.
async function deleteSelectedFiles() {
	const filesMarkedForDelete = document.getElementsByName("file_select");
	let markedFiles = [];
	let markedRows = [];
	for (let i = 0; i < filesMarkedForDelete.length; i++) {
		if (filesMarkedForDelete[i].checked) {
			markedFiles.push(filesMarkedForDelete[i].id.replace("file_select_", ""));
			markedRows.push(document.getElementById(filesMarkedForDelete[i].id.replace("file_select_", "")));
		}
	}

	const globalOverlay = document.getElementById("g_overlay");
	let newHTML = "<div class='inner-global-overlay'>";
	newHTML += "<h1>Delete Selected File(s)</h1>";
	newHTML += "<hr>";
	newHTML += "<b>Deleting " + markedFiles.length + " selected files...</b>";
	newHTML += "<br>";
	newHTML += "<br>";
	newHTML += "<br>";
	newHTML += "<button id='close_overlay' class='button-blue'>Okay</button>";
	newHTML += "</div>";
	globalOverlay.innerHTML = newHTML;
	globalOverlay.style.display = "inline";

	const closeOverlayButton = document.getElementById("close_overlay");
	closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
	closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

	if (await fetchData("/api/v3/rest/delete-files", "DELETE", markedFiles, deleteSelectedFilesDone)) {
		for (let i = 0; i < markedRows.length; i++) {
			markedRows[i].setAttribute("file_name", "deleted");
			markedRows[i].style.backgroundColor = "#990000";
			markedRows[i].style.color = "white";
			markedRows[i].innerHTML = '<td colspan="9"><b>File deleted.</b><br>Refresh the page or click <text style="color: #3476f6; font-weight: bold;">Refresh List</text>.</td>';
		}
	}
}

// When we finish deleting the files.
async function deleteSelectedFilesDone(data) {
	const globalOverlay = document.getElementById("g_overlay");
	if (data && data.success === true) {
		let newHTML = "<div class='inner-global-overlay'>";
		newHTML += "<h1>Deleted Selected File(s)</h1>";
		newHTML += "<hr>";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<b>File(s) successfully deleted.</b>";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<button id='close_overlay' class='button-blue'>Okay</button>";
		newHTML += "</div>";
		globalOverlay.innerHTML = newHTML;

		const closeOverlayButton = document.getElementById("close_overlay");
		closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
		closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);
	} else {
		let newHTML = "<div class='inner-global-overlay'>";
		newHTML += "<h1>Deleted Selected File(s)</h1>";
		newHTML += "<hr>";
		newHTML += "<b>Failed to delete files!</b>";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<br>";
		if (data.error) {
			newHTML += "Error: " + data.error;
		}
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<button id='close_overlay' class='button-blue'>Okay</button>";
		newHTML += "</div>";
		globalOverlay.innerHTML = newHTML;

		const closeOverlayButton = document.getElementById("close_overlay");
		closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
		closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);
	}
}

// Reset all sorting.
function resetAllSorting() {
	const sortName = document.getElementById("sort_name");
	if (sortName) {
		sortName.dataset.sortDirection = "";
		sortName.src = "/resources/images/icon/sort-avail.png";
	}

	const sortFavorite = document.getElementById("sort_favorite");
	if (sortFavorite) {
		sortFavorite.dataset.sortDirection = "";
		sortFavorite.src = "/resources/images/icon/sort-avail.png";
	}

	const sortSize = document.getElementById("sort_size");
	if (sortSize) {
		sortSize.dataset.sortDirection = "";
		sortSize.src = "/resources/images/icon/sort-avail.png";
	}

	const sortHits = document.getElementById("sort_hits");
	if (sortHits) {
		sortHits.dataset.sortDirection = "";
		sortHits.src = "/resources/images/icon/sort-avail.png";
	}

	const sortAccessed = document.getElementById("sort_accessed");
	if (sortAccessed) {
		sortAccessed.dataset.sortDirection = "";
		sortAccessed.src = "/resources/images/icon/sort-avail.png";
	}

	const sortAdded = document.getElementById("sort_added");
	if (sortAdded) {
		sortAdded.dataset.sortDirection = "";
		sortAdded.src = "/resources/images/icon/sort-avail.png";
	}
}

// When the sorting changes.
function changeSorting() {
	if (this.dataset.sortDirection === "" || this.dataset.sortDirection === "asc") {
		resetAllSorting();

		this.dataset.sortDirection = "desc";
		this.src = "/resources/images/icon/sort-desc.png";
	} else if (this.dataset.sortDirection === "desc") {
		resetAllSorting();

		this.dataset.sortDirection = "asc";
		this.src = "/resources/images/icon/sort-asc.png";
	}

	parseFileTable();
}

// Initialize.
function startFileManagement() {
	// File table
	if (fileTable !== null) {
		parseFileTable();
	}

	// Delete selected button
	if (FM_DeleteSelectedButton !== null) {
		FM_DeleteSelectedButton.addEventListener("click", buildDeleteSelectedOverlay);
	}

	// Select all checkbox
	if (FM_SelectAllCheckBox !== null) {
		FM_SelectAllCheckBox.addEventListener("change", toggleSelectAll);
	}

	// Preview overlay resize.
	if (filePreviewOverlay) {
		window.addEventListener("resize", resizefilePreviewOverlay);
		setTimeout(resizefilePreviewOverlay, 100);
	}

	// Refresh table button.
	if (refreshListButton) {
		refreshListButton.addEventListener("click", parseFileTable);
	}

	// Refresh table any time sorting has changed.
	const sortName = document.getElementById("sort_name");
	if (sortName) {
		sortName.addEventListener("click", changeSorting);
	}

	const sortFavorite = document.getElementById("sort_favorite");
	if (sortFavorite) {
		sortFavorite.addEventListener("click", changeSorting);
	}

	const sortSize = document.getElementById("sort_size");
	if (sortSize) {
		sortSize.addEventListener("click", changeSorting);
	}

	const sortHits = document.getElementById("sort_hits");
	if (sortHits) {
		sortHits.addEventListener("click", changeSorting);
	}

	const sortAccessed = document.getElementById("sort_accessed");
	if (sortAccessed) {
		sortAccessed.addEventListener("click", changeSorting);
	}

	const sortAdded = document.getElementById("sort_added");
	if (sortAdded) {
		sortAdded.addEventListener("click", changeSorting);
	}
}
