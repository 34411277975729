const markNoSrc = "/resources/images/mark-no.png";
const markYesSrc = "/resources/images/mark-yes.png";
const reCaptchaPublicKey = '6LdLObggAAAAAKpFMHeya31NNibX9VRDOi8NAGZ_';

async function authInit() {
	let loginButton = document.getElementById("login_button");
	if (loginButton) {
		loginButton.addEventListener("click", loginUser);

		let eMailTextBox = document.getElementById("email");
		if (eMailTextBox) {
			eMailTextBox.addEventListener("keypress", function(e) {
				if (e.code === 'Enter') {
					loginUser();
				}
			});
		}

		let passwordTextBox = document.getElementById("password");
		if (passwordTextBox) {
			passwordTextBox.addEventListener("keypress", function(e) {
				if (e.code === 'Enter') {
					loginUser();
				}
			});
		}

	} else {
		// Password listener #1.
		let passwordTextBox = document.getElementById("password");
		if (passwordTextBox) {
			passwordTextBox.addEventListener("input", validatePassword);
			passwordTextBox.addEventListener("input", validatePasswordsMatch);
			passwordTextBox.addEventListener("keypress", function(e) {
				if (e.code === 'Enter') {
					createAccount();
				}
			});
		}

		// Password listener #2.
		let passwordTextBox2 = document.getElementById("password2");
		if (passwordTextBox2) {
			passwordTextBox2.addEventListener("input", validatePasswordsMatch);
			passwordTextBox2.addEventListener("keypress", function(e) {
				if (e.code === 'Enter') {
					createAccount();
				}
			});
		}

		// Create account button listener.
		let createAccountButton = document.getElementById("create_account_button");
		if (createAccountButton) {
			createAccountButton.addEventListener("click", createAccount);
		}
	}
}

async function validatePassword() {
	let passwordTextBox = document.getElementById("password");
	let feedbackPasswordGT = document.getElementById("feedback_password_gt");
	let feedbackPasswordLT = document.getElementById("feedback_password_lt");
	if (!feedbackPasswordGT || !feedbackPasswordLT || !passwordTextBox) {
		return false;
	}

	if (passwordTextBox.value.length >= 8 && passwordTextBox.value.length <= 128) {
		feedbackPasswordGT.style.color = "green";
		feedbackPasswordLT.style.color = "green";
		feedbackPasswordGT.getElementsByTagName("img")[0].src = markYesSrc;
		feedbackPasswordLT.getElementsByTagName("img")[0].src = markYesSrc;
		return true;
	} else if (passwordTextBox.value.length >= 8 && !passwordTextBox.value.length <= 128) {
		feedbackPasswordGT.style.color = "green";
		feedbackPasswordLT.style.color = "red";
		feedbackPasswordGT.getElementsByTagName("img")[0].src = markYesSrc;
		feedbackPasswordLT.getElementsByTagName("img")[0].src = markNoSrc;
		return false;
	} else if (!passwordTextBox.value.length >= 8 && passwordTextBox.value.length <= 128) {
		feedbackPasswordGT.style.color = "red";
		feedbackPasswordLT.style.color = "green";
		feedbackPasswordGT.getElementsByTagName("img")[0].src = markNoSrc;
		feedbackPasswordLT.getElementsByTagName("img")[0].src = markYesSrc;
		return false;
	} else {
		feedbackPasswordGT.style.color = "red";
		feedbackPasswordLT.style.color = "red";
		feedbackPasswordGT.getElementsByTagName("img")[0].src = markNoSrc;
		feedbackPasswordLT.getElementsByTagName("img")[0].src = markNoSrc;
		return false;
	}
}

async function validatePasswordsMatch() {
	let feedbackPasswordMatch = document.getElementById("feedback_password_match");

	let passwordOne = document.getElementById("password");
	let passwordTwo = document.getElementById("password2");
	if (!feedbackPasswordMatch || !passwordOne || !passwordTwo) {
		return false;
	}

	if (passwordOne.value === "" || passwordOne.value === undefined) {
		return false;
	}

	if (passwordOne.value === passwordTwo.value) {
		feedbackPasswordMatch.style.color = "green";
		feedbackPasswordMatch.getElementsByTagName("img")[0].src = markYesSrc;
		return true;
	} else {
		feedbackPasswordMatch.style.color = "red";
		feedbackPasswordMatch.getElementsByTagName("img")[0].src = markNoSrc;
		return false;
	}
}

async function loginUser() {
	if (this.disabled) {
		return;
	}

	let loginButton = this;
	let eMailTextBox = document.getElementById("email");
	let passwordTextBox = document.getElementById("password");
	let loginNotifications = document.getElementById("login_notifications");
	let rememberMeBox = document.getElementById("rememberme");
	if (eMailTextBox && passwordTextBox && loginNotifications && rememberMeBox) {
		if (!eMailTextBox.value) {
			loginNotifications.innerHTML = "<text style='color: red;'>Invalid E-Mail.</text>";
			loginNotifications.hidden = false;
		} else if (!passwordTextBox.value || passwordTextBox.value.length < 8) {
			loginNotifications.innerHTML = "<text style='color: red;'>Invalid password.</text>";
			loginNotifications.hidden = false;
		} else {
			loginButton.disabled = true;
			loginNotifications.hidden = false;

			if (typeof grecaptcha == 'undefined') {
				console.error('reCaptcha is unavailable, was it blocked by the client?');
				loginNotifications.innerHTML = "<text style='color: red;'>Failed to load Google reCaptcha, is it blocked via an AdBlock?<br>Unblock or allow reCaptcha, refresh the page, and try again.</text>";
			} else {
				loginNotifications.innerHTML = "<text style='color: green;'>Signing you in, please wait...</text><img width='18' src='/resources/images/loading.gif' alt='Trying to log you in..'>";

				grecaptcha.enterprise.execute(reCaptchaPublicKey, {action: 'LOGIN'}).then(async function (token) {
					let response = await fetchData('/api/v3/session/login', 'POST', {'captcha_token': token, 'email': eMailTextBox.value, 'password': passwordTextBox.value, 'remember_me': rememberMeBox.checked});
					if (response.success) {
						if (response.data.success) {
							window.location = "/";
						} else {
							loginButton.disabled = false;

							if (response.data.error) {
								loginNotifications.innerHTML = "<text style='color: red;'>" + response.data.error + "</text>";
							} else {
								loginNotifications.innerHTML = "<text style='color: red;'>Request failed, try again.</text>";
							}
						}
					} else {
						loginButton.disabled = false;

						if (response.data.error) {
							loginNotifications.innerHTML = "<text style='color: red;'>" + response.data.error + "</text>";
						} else {
							loginNotifications.innerHTML = "<text style='color: red;'>Request failed, try again.</text>";
						}
					}
				});
			}
		}
	}
}

async function createAccount() {
	if (this.disabled) {
		return;
	}

	let requirementsSection = document.getElementById("requirements");
	if (!requirementsSection) {
		console.error("This wasn't supposed to happen!");
		return;
	}

	if (await validatePassword() === false) {
		console.warn("Password is invalid.");
		requirementsSection.classList.remove("blinky");
		// Force redraw so the animation will run again.
		void requirementsSection.offsetWidth;
		requirementsSection.classList.add("blinky");
	} else if (await validatePasswordsMatch() === false) {
		console.warn("Passwords don't match.");
		requirementsSection.classList.remove("blinky");
		// Force redraw so the animation will run again.
		void requirementsSection.offsetWidth;
		requirementsSection.classList.add("blinky");
	} else {
		requirementsSection.classList.remove("blinky");

		let createAccountButton = this;
		let eMailTextBox = document.getElementById("email");
		let passwordTextBox = document.getElementById("password");
		let passwordTextBox2 = document.getElementById("password2");
		let createAccountNotifications = document.getElementById("create_account_notifications");
		if (createAccountButton && eMailTextBox && passwordTextBox && passwordTextBox2 && createAccountNotifications) {
			createAccountButton.disabled = true;
			createAccountNotifications.hidden = false;

			if (typeof grecaptcha == 'undefined') {
				console.error('reCaptcha is unavailable, was it blocked by the client?');
				createAccountNotifications.innerHTML = "<text style='color: red;'>Failed to load Google reCaptcha, is it blocked via an AdBlock?<br>Unblock or allow reCaptcha, refresh the page, and try again.</text>";
			} else {
				createAccountNotifications.innerHTML = "<text style='color: white;'>Creating your account, please wait...</text><img width='18' src='/resources/images/loading.gif' alt='Trying to log you in..'>";

				grecaptcha.enterprise.execute(reCaptchaPublicKey, { action: 'CREATE_ACCOUNT' }).then(async function(token) {
					let response = await fetchData('/api/v3/session/create-account', 'POST', { 'captcha_token': token, 'email': eMailTextBox.value, 'password': passwordTextBox.value, 'password2': passwordTextBox2.value });
					if (response.success) {
						if (response.data.success) {
							window.location = "/login?verify-email";
						} else {
							createAccountButton.disabled = false;

							if (response.data.error) {
								createAccountNotifications.innerHTML = "<text style='color: red;'>Request failed: " + response.data.error + "</text>";
							} else {
								createAccountNotifications.innerHTML = "<text style='color: red;'>Request failed, try again.</text>";
							}
						}
					} else {
						createAccountButton.disabled = false;

						if (response.data.error) {
							createAccountNotifications.innerHTML = "<text style='color: red;'>Request failed: " + response.data.error + "</text>";
						} else {
							createAccountNotifications.innerHTML = "<text style='color: red;'>Request failed, try again.</text>";
						}
					}
				});
			}
		}
	}
}
